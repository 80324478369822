<template>
  <div class="">
    <div class="my-5">
      <div class="row">
        <div class="col-sm-12 text-start">
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="userName" class="text-muted mb-2">課程名稱</label>
              
                <input type="text" class="form-control" id="userName"
                  v-model="data.courseName"
                  readonly>
                
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="category" class="text-muted mb-2">課程類別</label>
              <input type="text" class="form-control" id="account"
                v-model="data.courseCategoryId"
                readonly>
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="authorName" class="text-muted mb-2">授課教師</label>
                <input type="text" class="form-control" id="userName"
                  v-model="data.authorName"
                  readonly>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="price" class="text-muted mb-2">價格</label>
              <input type="text" class="form-control" id="account"
                v-model="data.price"
                readonly>
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="courseHashtag" class="text-muted mb-2">課程標籤</label>
              <div>
                <span v-for="tag in data.courseHashtag" :key="tag" class="badge rounded-pill course__tag">{{ tag }}</span>
              </div>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="selected" class="text-muted mb-2">精選課程</label>
              <p>{{ data.selected ? '是' : '否' }}</p>
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group col-md-12 mb-2">
              <label for="description" class="text-muted mb-2">課程描述</label>
              <div v-html="data.courseDescription.split('\n').join('<br>')"></div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="form-group col-md-6 mb-2">
              <label for="coursePrevImgUrl" class="text-muted mb-2">課程圖片</label>
              <div>
                <img :src="data.coursePrevImgUrl" alt="" width="200">
              </div>
            </div>
            <div class="form-group col-md-6 mb-2">
              <label for="coursePrevImgUrl1" class="text-muted mb-2">課程書本圖片</label>
              <div>
                <img :src="data.coursePrevImgUrl1" alt="" width="200">
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 text-end">
            <button class="btn btn-main" type="submit"
            >審核完成</button>
          </div>
      </div>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from 'vuex';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__Review',
  components: {
  },
  data() {
    return {
      data: [],
      metaTitle: '審核 / 課程 / 管理 - 元學堂'
    };
  },
  methods: {
    getData() {
      this.$http.get(`${this.$API_PATH}/Course?CourseId=6`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        this.data = { ...res.data.data };
      }).catch((err) => {
        // this.$router.push('/error');
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '權限不足',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .pageNum {
    cursor: pointer;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
      color: gray;
      cursor: default;
    }
  }
  ::v-deep #vgt-table tbody tr td:nth-of-type(1),
  ::v-deep #vgt-table tbody tr td:last-of-type,
  ::v-deep #vgt-table tbody tr td:nth-of-type(9) {
    text-align: center;
  }
  ::v-deep #vgt-table tbody tr td:last-of-type span {
    display: flex;
    flex-direction: column;
  }
  ::v-deep #vgt-table td span {
    line-height: 1.4rem;
  }

  ::v-deep #vgt-table thead th {
    min-width: 95px !important;
    padding-right: .75rem !important;
  }

  [id*="json-to-csv-"] {
    display: block;
    text-align: end;
  }
</style>
